<template>
  <div class="index-view">
    <swiper-banner :list="banners"></swiper-banner>

    <div class="safety-wraper">
      <div class="index-panel" style="margin-top: 48px">
        <div class="en-title">PLATFORM PERFORMANCE</div>
        <div class="index-title">平台业绩</div>
        <div class="performance-group">
          <div class="performance-group-item">
            <div class="performance-row">
              <span class="number-text">{{
                platformPerformance.applyEnterprise
              }}</span
              >家
            </div>
            <div class="performance-item-title">申请合作企业</div>
            <div class="performance-item-en-text">
              Application for<br />
              cooperative enterprise
            </div>
          </div>

          <div class="performance-group-item">
            <div class="performance-row">
              <span class="number-text">{{
                platformPerformance.collEnterprise | currency
              }}</span
              >家
            </div>
            <div class="performance-item-title">已合作企业</div>
            <div class="performance-item-en-text">
              Application for<br />
              cooperative enterprise
            </div>
          </div>

          <div class="performance-group-item">
            <div class="performance-row">
              <span class="number-text">{{
                platformPerformance.applySing | currency
              }}</span
              >单
            </div>
            <div class="performance-item-title">申请单量</div>
            <div class="performance-item-en-text">
              Application for<br />
              cooperative enterprise
            </div>
          </div>

          <div class="performance-group-item">
            <div class="performance-row">
              <span class="number-text">{{
                platformPerformance.approve | currency
              }}</span
              >单
            </div>
            <div class="performance-item-title">审批通过</div>
            <div class="performance-item-en-text">
              Application for<br />
              cooperative enterprise
            </div>
          </div>

          <div class="performance-group-item">
            <div class="performance-row">
              <span class="number-text">{{
                platformPerformance.successfulLoan | currency
              }}</span
              >万
            </div>
            <div class="performance-item-title">成功放款</div>
            <div class="performance-item-en-text">
              Application for<br />
              cooperative enterprise
            </div>
          </div>
        </div>
      </div>

      <div class="index-panel" style="margin-top: 48px">
        <div class="en-title">PROVIDE PROFESSIONAL SERVICES</div>
        <div class="index-title">我们提供的服务</div>

        <div class="service-group">
          <div class="service-panel" @click="handleToPanelDetail(1)">
            <div class="service-panel-icon">
              <img src="@/assets/image/home_list.png" alt="" />
            </div>
            <div class="service-content">
              <div class="service-title">投标项目备案</div>
              <div class="service-en-text">
                Lorem ipsum dolor sit amet adipisicing elit.
              </div>
            </div>
          </div>

          <div class="service-panel" @click="handleToPanelDetail(2)">
            <div class="service-panel-icon">
              <img src="@/assets/image/home_apply.png" alt="" />
            </div>
            <div class="service-content">
              <div class="service-title">投标保证金申请贷款</div>
              <div class="service-en-text">
                Lorem ipsum dolor sit amet adipisicing elit.
              </div>
            </div>
          </div>

          <div class="service-panel" @click="handleToPanelDetail(3)">
            <div class="service-panel-icon">
              <img src="@/assets/image/home_apply.png" alt="" />
            </div>
            <div class="service-content">
              <div class="service-title">采购贷款申请</div>
              <div class="service-en-text">
                Lorem ipsum dolor sit amet adipisicing elit.
              </div>
            </div>
          </div>

          <div class="service-panel">
            <div class="service-panel-icon">
              <img src="@/assets/image/home_more.png" alt="" />
            </div>
            <div class="service-content">
              <div class="service-title">更多服务、敬请期待</div>
              <div class="service-en-text">
                Lorem ipsum dolor sit amet adipisicing elit.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="index-panel" style="margin-top: 48px">
        <div class="en-title">PRODUCTS OF FINANCIAL SERVICES</div>
        <div class="index-title">合作供应商产品展示</div>

        <div class="more-row">
          <a href="javascript:void(0)" @click="goMore('product')">More >></a>
        </div>
        <div class="product-group">
          <div
            class="product-item"
            v-for="item in platProductList"
            :key="item.id"
          >
            <div class="product-image">
              <img :src="item.poster" alt="" />
            </div>
            <div class="product-content">
              <div class="product-title">{{ item.name }}</div>
              <div class="product-price">
                {{ item.price | currency }} 万元/{{ item.unit }}
              </div>
              <div class="product-tag-group">
                <div class="product-tag-item">技术服务</div>
                <div class="product-tag-item">技术服务</div>
                <div class="product-tag-item">软件</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="index-panel" style="margin-top: 48px">
        <div class="en-title">PLATFORM POLICY</div>
        <div class="index-title">平台政策</div>

        <div class="platform-policy-group">
          <div class="platform-policy-image">
            <img src="@/assets/image/home_policy.jpg" alt="" />
          </div>
          <div class="platform-policy-content">
            <div class="platform-policy-item">
              <div class="policy-title">会员制</div>
              <p>
                All of our employees receive cleaning methodsreceive cleaning
                methods receive cleaning methods and equipment training.
              </p>
            </div>

            <div class="platform-policy-item">
              <div class="policy-title">公平原则</div>
              <p>
                All of our employees receive cleaning methodsreceive cleaning
                methods receive cleaning methods and equipment training.
              </p>
            </div>

            <div class="platform-policy-item">
              <div class="policy-title">可服务性</div>
              <p>
                All of our employees receive cleaning methodsreceive cleaning
                methods receive cleaning methods and equipment training.
              </p>
            </div>

            <div class="platform-policy-item">
              <div class="policy-title">在线服务</div>
              <p>
                All of our employees receive cleaning methodsreceive cleaning
                methods receive cleaning methods and equipment training.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="index-panel" style="margin-top: 48px">
        <div class="en-title">COOPERATION PARTNER</div>
        <div class="index-title">合作伙伴</div>

        <div class="more-row">
          <a href="javascript:void(0)" @click="goMore('partner')">More >></a>
        </div>

        <div class="partner-group">
          <div
            class="partner-item"
            v-for="item in platMemberList"
            :key="item.id"
          >
            <div class="partner-banner">
              <img :src="item.logo" alt="" />
            </div>
            <div class="partner-title">
              <p>{{ item.name }}</p>
            </div>

            <div class="partner-count">
              年投标规模：{{ item.bidAmount | currency }} 万元
            </div>
            <div class="partner-sales">
              年销售额：{{ item.salse | currency }} 万元
            </div>
          </div>
        </div>
      </div>

      <div class="index-panel" style="margin-top: 48px">
        <div class="en-title">TENDER INFORMATION</div>
        <div class="index-title">项目信息</div>

        <div class="more-row">
          <a href="javascript:void(0)" @click="goMore('project')">More >></a>
        </div>

        <div class="project-info-group">
          <div
            class="project-item"
            v-for="item in platSubjectList"
            :key="item.id"
          >
            <div class="project-banner">
              <img :src="item.poster" alt="" />
            </div>
            <div class="project-title">
              <p>{{ item.name }}</p>
            </div>

            <div class="project-from">招标方：{{ item.memberName }}</div>
            <div class="project-size">
              标地规模：{{ item.scale | currency }} 万元
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SwiperBanner from "./components/swiperBanner.vue";
export default {
  components: {
    SwiperBanner,
  },
  data() {
    return {
      banners: [],

      platformPerformance: {
        applyEnterprise: 0,
        applySing: 0,
        approve: 0,
        collEnterprise: 0,
        successfulLoan: 0,
      },

      platProductList: [],

      platMemberList: [],

      platSubjectList: [],
    };
  },
  mounted() {
    this.getBanners();
    this.getPlatformPerformance();
    this.getPlatProductList();
    this.getPlatMemberList();
    this.getPlatSubjectList();
  },
  methods: {
    getBanners() {
      //获取轮播图
      this.$axios.get("/banner/showHomePageBanner").then((res) => {
        if (res.state == 200) {
          this.banners = res.data || [];
        }
      });
    },
    getPlatformPerformance() {
      this.$axios.get("/plat/platformPerformance").then((res) => {
        if (res.state == 200) {
          this.platformPerformance = res.data;
        }
      });
    },
    getPlatProductList() {
      this.$axios.get("/product/platProductList").then((res) => {
        if (res.state == 200) {
          this.platProductList = res.data;
        }
      });
    },
    getPlatMemberList() {
      this.$axios.get("/member/platMemberList").then((res) => {
        if (res.state == 200) {
          this.platMemberList = res.data;
        }
      });
    },
    getPlatSubjectList() {
      this.$axios.get("/subject/platSubjectList").then((res) => {
        if (res.state == 200) {
          this.platSubjectList = res.data;
        }
      });
    },
    goMore(key) {
      if (key == "product") {
        this.$router.push("/product-list");
      } else if (key == "partner") {
        this.$router.push("/company-search");
      } else if (key == "project") {
        this.$router.push("/project-search");
      }
    },
    handleToPanelDetail(id) {
      this.$router.push(`/panel-detail/${id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.safety-wraper {
  width: $safety-width;
  margin: 0 auto;

  .index-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    .en-title {
      color: rgba(153, 153, 153, 0.34);
      line-height: 20px;
      letter-spacing: 6px;
      background: linear-gradient(180deg, #d8d8d8 0%, #f7f7f7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
    }
    .index-title {
      color: #333333;
      line-height: 40px;
      font-size: 28px;
      position: relative;
      &:after {
        content: "";
        background-color: #3883ff;
        width: 39px;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 5px;
      }
    }
  }
  .performance-group {
    margin-top: 52px;
    display: flex;
    justify-content: center;
    border: 1px solid #ededed;
    padding: 28px 0 52px 0;
    .performance-group-item {
      padding: 0 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      .performance-row {
        display: flex;
        align-items: center;
        color: #999999;
        font-size: 24px;
        line-height: 33px;
      }
      .number-text {
        color: #004375;
        line-height: 56px;
        letter-spacing: 1px;
        font-size: 40px;
      }
      .performance-item-title {
        color: #333333;
        line-height: 30px;
        font-size: 22px;
        margin-top: 23px;
      }
      .performance-item-en-text {
        color: #c5c5c5;
        line-height: 16px;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .service-group {
    display: flex;
    .service-panel:hover {
      cursor: pointer;
    }
    .service-panel {
      display: flex;
      border: 1px solid #ededed;
      margin: 0 10px;
      margin-top: 52px;
      padding: 20px;
      box-sizing: border-box;
      flex-shrink: 0;
      //   width: 287px;
      .service-panel-icon {
        width: 50px;
        height: 50px;
        // background-color: #ddd;
      }
      .service-content {
        margin-left: 15px;
        .service-title {
          color: #333333;
          line-height: 30px;
          font-size: 22px;
        }
        .service-en-text {
          font-size: 14px;
          color: #999999;
          line-height: 18px;
          width: 184px;
        }
      }
    }
  }

  .more-row {
    width: 100%;
    margin-top: 52px;
    text-align: right;
    a {
      text-decoration: none;
      color: #004375;
    }
  }

  .product-group {
    display: flex;
    flex-wrap: wrap;

    .product-item {
      display: flex;
      flex-shrink: 0;
      width: 587px;
      border: 1px solid #efefef;
      padding: 15px;
      box-sizing: border-box;
      margin: 5px;
      line-height: 30px;
      .product-image {
        width: 261px;
        height: 155px;
        background-color: #dddddd;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .product-content {
        margin-left: 20px;
        flex: 1;

        .product-title {
          color: #333333;
          //   line-height: 28px;
          font-size: 20px;
        }
        .product-price {
          color: #fa6400;
          //   line-height: 22px;
          font-size: 16px;
        }
        .product-tag-group {
          display: flex;
          flex-wrap: wrap;
          .product-tag-item {
            padding: 10px;

            background: #eeeeee;
            margin-right: 10px;

            color: #666666;
            line-height: 17px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .platform-policy-group {
    margin-top: 52px;
    display: flex;
    .platform-policy-image {
      width: 536px;
      height: 599px;
      background-color: #dddddd;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .platform-policy-content {
      flex: 1;
      margin-left: 56px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .platform-policy-item {
        .policy-title {
          font-size: 22px;
          line-height: 30px;
          color: #333333;
          position: relative;
          margin-bottom: 30px;
          &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 5px;
            width: 26px;
            height: 2px;
            background-color: #3883ff;
          }
        }
        p {
          width: 470px;

          color: #999999;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .partner-group {
    // margin-top: 52px;
    display: flex;
    flex-wrap: wrap;
    .partner-item {
      width: 270px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 0px #d7dfe5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px;
      padding-bottom: 33px;
      .partner-banner {
        width: 270px;
        height: 169px;
        background-color: #dddddd;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
        // .banner-cover {
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 11px;
        //   background-color: #ffffff;
        //   &:before {
        //     content: "";
        //     width: 0;
        //     height: 0;
        //     border: 10px solid;
        //     border-color: transparent transparent transparent;
        //     position: absolute;
        //     top: 0px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //   }
        // }
      }
      .partner-title {
        height: 50px;
        color: #333333;
        line-height: 33px;
        font-size: 24px;
        margin-bottom: 42px;
        position: relative;
        margin-top: 18px;
        p {
          text-align: center;
          overflow: hidden;

          // display: inline-block;
          // white-space: nowrap;
          // width: 270px;
          // text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        p:hover {
          // text-overflow: inherit;
          // overflow: visible;
          -webkit-line-clamp: 10;
          font-size: 20px;
        }
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 20px;
          width: 26px;
          height: 2px;
          background-color: #3883ff;
        }
      }
      .partner-count {
        font-size: 18px;
        color: #999999;
        line-height: 25px;
      }
      .partner-sales {
        font-size: 18px;
        color: #999999;
        line-height: 25px;
      }
    }
  }

  .project-info-group {
    // margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    .project-item {
      width: 270px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 0px #d7dfe5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px;
      padding-bottom: 33px;
      .project-banner {
        width: 270px;
        height: 169px;
        background-color: #dddddd;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .project-tag {
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 11px;
          //   background-color: #ffffff;
          //   &:before {
          //     content: "";
          //     width: 0;
          //     height: 0;
          //     border: 10px solid;
          //     border-color: transparent transparent transparent;
          //     position: absolute;
          //     top: 0px;
          //     left: 50%;
          //     transform: translateX(-50%);
          //   }
        }
      }
      .project-title {
        height: 50px;
        color: #333333;
        line-height: 33px;
        font-size: 24px;
        margin-bottom: 42px;
        position: relative;
        margin-top: 18px;
        p {
          text-align: center;
          overflow: hidden;

          // display: inline-block;
          // white-space: nowrap;
          // width: 270px;
          // text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        p:hover {
          // text-overflow: inherit;
          // overflow: visible;
          -webkit-line-clamp: 10;
          font-size: 20px;
        }
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 20px;
          width: 26px;
          height: 2px;
          background-color: #3883ff;
        }
      }
      .project-from {
        font-size: 18px;
        width: 270px;
        height: 25px;
        text-align: center;
        color: #999999;
        line-height: 25px;

        overflow: hidden;

        // display: inline-block;
        // white-space: nowrap;
        // width: 270px;
        // text-overflow: ellipsis;

        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .project-size {
        font-size: 18px;
        color: #999999;
        line-height: 25px;
      }
    }
  }
}
</style>